.layout {
    flex: 1;
    display: grid;
    grid-template-areas:
      'header'
      'main'
      'sidebar'
      'footer';
    grid-template-rows: auto 1fr auto auto;
    grid-template-columns: 1fr;
    padding-top: 100px; /* Add padding to the layout to make space for the fixed header */
  }
  
  @media (min-width: 768px) {
    .layout {
      grid-template-areas:
        'header header header'
        'main main sidebar'
        'footer footer footer';
      grid-template-rows: auto 1fr auto;
      grid-template-columns: 3fr 1fr;
      padding-top: 100px; /* Add padding to the layout to make space for the fixed header */
    }
  }
  
  /* div {
  border: solid 1px #ddd; 
    min-height: 20vh;
    min-width: 20vw;
  } */
  
  .header,
  .footer {
    grid-column: span 3;
  }
  
  .header {
    grid-area: header;
    background: var(--primary-color);
    min-height: 100px;
    padding: 1rem; 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensure the header is on top */
  }
  
  .main {
    grid-area: main;
    background: var(--light-color);
    padding: 2rem;
    min-height: 100vh;
    
  }
  
  .sidebar {
    grid-area: sidebar;
    background: var(--light-color);
    padding: 2rem;
    min-height: 100vh;
    min-width: 20vw;
   
  }
  
  .footer {
    grid-area: footer;
    background-color: var(--secondary-color);
    padding: 1rem;
    text-align: center;
    min-height: 20vh;
  }
