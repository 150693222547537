.scroll-container {
    overflow-y: auto;
    height: 100vh;
  
  }
  
  .head{
    width: 100%;
    background-color: rgb(219, 62, 62);
  }
  
  #facebook-plugin-container {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  
  #facebook-plugin {
    width: 100%;
    padding-top: 10px;
  }
  
  .element {
    position: relative;
    margin-top: 10px;
    padding-top: 10px;
    font-size: 20px;
    text-align: center;
    color: white;
  }
  
  .box {
    padding-top: 10px;
    width: 100%;
    height: 200px;
    border-radius: 1%;
    background-image: url('../../assests/images/Bgheader.jpeg');
  }
  
  .donate-container {
    padding-top: 20px;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    text-align: center;
  }
  
  .button {
    padding: 10px;
    width: 100px;
    height: 40px;
    border-radius: 25%;
    background-color: yellow;
    color: black;
    font-size: 15px;
    font-weight: 100px;
    text-decoration: none;
  }
  
  .button:hover {
    background-color: white;
    /* Change background color on hover */
    color: black;
    /* Change text color on hover */
    border: 2px solid #4CAF50;
    /* Add a border on hover */
  }
  
  .button:active {
    background-color: #4CAF50;
    /* Change background color when clicked */
    color: white;
    /* Change text color when clicked */
    border: 2px solid white;
    /* Add a border when clicked */
  }
  
  .p {
    font-size: 15px;
    color: white;
    text-align: center;
  }