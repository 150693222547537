
/* Landing Page */
.landing {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
    position: relative;
    /* background: url('../../assests/images/chicago.jpeg') no-repeat center center/cover; */
    height: 100vh;
  }
  
  .landing img.logo {
    mix-blend-mode: multiply;
    width: 200px;
    height:180;
    border-radius: 30%;
  }
  
  .landing img.logo:hover {
  transition: 0.5s;
  transform: scale(1.3);
  }

 
  .landing-inner {
    color: var(--dark-color);
    height: 100%;
    width:80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--light-color);
  }