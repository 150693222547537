.footer-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color:var(--dark-color);
}

.footer-grid > div {
    background-color: var(--secondary-color);
    padding: 1em;
}

.footer-grid > div:nth-last-child(1) {
    background-color: #d8e1b9;
    
}

.box4 {
    grid-column: 1/4;
    /* border: 1px solid  var(--secondary-color); */
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
    .footer-grid {
        grid-template-columns: 1fr;
    }

    .box4 {
        grid-column: 1 / -1;
    }
}