.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-column-gap: 1em;
  grid-row-gap: 1em; */
  grid-gap: 1em;
  grid-auto-rows: minmax(100px, auto);
}

.wrapper > div {
    background-color:var(--light-gray-color);
    padding: 1em;
}


/* .wrapper > div:nth-child(odd){
    background-color:#ddd;
} */


.birthday {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    /* grid-gap: 0.1em; */
}

.birthday .todays-birthdays,
.birthday .upcoming-birthdays {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: minmax(100px, auto);
    /* grid-gap:0.1em; */
}

.birthday > div {
    border: none;
    padding: 0.3em;
}

.birthday .todays-birthdays > div,
.birthday .upcoming-birthdays > div  {
    border: var(--secondary-color) solid 1px;
    /* padding: 0.5em; */
}

.birthday .todays-birthdays ,
.birthday .upcoming-birthdays  {
    grid-auto-rows: minmax(50px, auto);
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
    .wrapper,
    .birthday,
    .birthday .todays-birthdays,
    .birthday .upcoming-birthdays
     {
        grid-template-columns: 1fr;
    }
    
}