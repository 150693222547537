.active {
    background-color:var(--active-color);
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--dark-color);
}

nav ul {
    display: flex;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: var(--light-color);
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
    font-weight: bold;
}

nav ul li a:not(.active):hover {
    background-color: var(--secondary-color);
}

nav a:hover {
    color: var(--dark-color);
}

nav .welcome span {
    margin-right: 0.6rem;
}

nav .menu {
    display: none;
    position: absolute;
    top: 3.5rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: var(--light-color);
    border-radius: 0.2rem;
}

@media(max-width: 768px) {
    nav .menu {
        display: flex;
        cursor: pointer;
    }

    nav {
        flex-direction: column;
        align-items: start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-top: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        /* text-align: center; */
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
        cursor: pointer;
    }

    nav h1 {
        margin-bottom: 1rem;
      }
    
    nav .welcome {
        display: none;
    }
}