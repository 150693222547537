.brand img.logo {
  mix-blend-mode: color-burn;
  width: 70px;
  height:60px;
  border-radius: 30%;
}

.brand img.logo:hover {
transition: 0.5s;
transform: scale(1.4);
}

.brand .title {
    font-weight: bold;
    text-decoration: none;
    color: var(--light-color);
    position: absolute;
    top: 2rem;
    left: 6rem;
   
  }

  .brand .title:hover {
    transition: 0.5s;
    transform: scale(1.04);
    color: var(--dark-color);
  }

  @media screen and (max-width: 769px) {
    .brand .title {
      display: none;
    }

    .brand img.logo {
      mix-blend-mode: color-burn;
      width: 100px;
      height:90px;
      border-radius: 30%;
    }
  }

